.needs-audit-container {
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 0 10px var(--shadow-color);
    max-width: 100%;
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .audit-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .audit-item {
    padding: 10px;
    border-bottom: 1px solid var(--separator-color);
    font-size: 1rem;
    display: flex;
    justify-content: space-between; /* Align content between item text and edit button */
    align-items: center; /* Vertically center content */
  }
  
  .audit-item:last-child {
    border-bottom: none;
  }
  
  /* Edit button styling */
  .edit-btn {
    padding: 6px 12px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit-btn:hover {
    background-color: #ff7800;
  }
  