
.export-btn,
.re-audit-btn {
    padding: 12px 25px;
    cursor: pointer;
    background-color: var(--accent-color);
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.export-btn:hover,
.re-audit-btn:hover {
    background-color: #5fa9a2;
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .tabs {
        flex-direction: column;
        align-items: center;
    }

    .tab {
        margin-bottom: 10px;
        width: 90%;
        text-align: center;
    }

    .action-buttons {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }

    .export-btn,
    .re-audit-btn {
        width: 80%;
    }
}

/* Page container for consistent alignment */
.needs-audit-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
